import Header from "./Header/Header";
import DemoButton from "./DemoButton/DemoButton";
import Divider from "./Divider/Divider";
import Feature from "./FeatureGrid/Feature";
import Footer from "./Footer/Footer";
import FeatureGrid from "./FeatureGrid/FeatureGrid";
import TitleBig from "./TitleBig/TitleBig";
import TitleSub from "./TitleSub/TitleSub";
import ScreenshotBig from "./ScreenshotBig/ScreenshotBig";
import Video from "./Video/Video";
import "./App.css";
import { useRef } from "react";

const App = () => {
  const employeeDirectoryRef = useRef<HTMLElement | null>(null);
  const matrixOrgsRef = useRef<HTMLElement | null>(null);
  const onboardingRef = useRef<HTMLElement | null>(null);
  const integrationRef = useRef<HTMLElement | null>(null);

  console.log("");
  console.log(
    "We are looking for frontend developers and UX designers to join our team!"
  );
  console.log("");
  console.log("We're nice people. So you have to be nice people too.");
  console.log("");
  console.log("Say hi: hello@elv.tech");
  console.log("");

  return (
    <div className="app">
      <div className="center">
        <Header
          employeeDirectory={employeeDirectoryRef}
          matrixOrgs={matrixOrgsRef}
          onboarding={onboardingRef}
          integration={integrationRef}
        />
        <div className="content">
          <section>
            <TitleBig>
              Elv is for modern
              <br />
              HR teams.
            </TitleBig>
            <TitleSub>
              Elv is a modern employee directory that helps you
              <br />
              onboard and manage your employees.
              <br />
              <br />
              Even in complex matrix organizations.
            </TitleSub>
            <DemoButton />
            <ScreenshotBig />
          </section>
          <Divider />
          <section ref={employeeDirectoryRef}>
            <TitleBig>See the unseen.</TitleBig>
            <TitleSub>
              See all your companies, departments, employees and <br />
              projects, all in one place. Your new employee directory.
              {/* Elv gives you visual insights into your matrix organization, your
              teams and your people. See the big picture and drill down into the
              details. */}
            </TitleSub>
            <FeatureGrid>
              <Feature
                title="See your whole organization chart."
                text="Nothing missing, all up to date."
              />
              <Feature
                title="Create your own views in seconds."
                text="Create new roles, staff new teams, experiment freely."
              />
              <Feature
                title="Control cost."
                text="See all your external consultants and freelancers in one place."
              />
              <Feature
                title="Lightning fast search."
                text="Search for anything, get results instantly."
              />
              <Feature
                title="Lightning fast filtering."
                text="Sift through your employees as easily as you sift through an apartment search."
              />
              <Feature
                title="Drag and drop. Zoom and pan."
                text="Move people around in seconds."
              />
            </FeatureGrid>
          </section>
          <Divider />
          <section ref={employeeDirectoryRef}>
            <TitleBig>Dashboard.</TitleBig>
            <TitleSub>
              All your people data visualized over time. Deep insights become
              obvious.
            </TitleSub>
            <FeatureGrid>
              <Feature
                title="Analytics."
                text="Most data is more interesting over time. Get HR data graphs tailored to your needs, and see the trends over time."
              />
              <Feature
                title="Filtering."
                text="Filter your people data and instantly see how details trend compared to the rest of your organization."
              />
              <Feature
                title="Knowledge lost."
                text="A veteran quitting is not the same as a graduate quitting, understand the effects."
              />
              <Feature
                title="An aging workforce."
                text="See the age distribution of your workforce and plan for the future."
              />
              <Feature
                title="Too many consultants."
                text="The costs add up. How many consultants do you have today versus two years ago?"
              />
              <Feature
                title="Too many hats."
                text="Yes-people end up with more roles. See the roles pile up over time and act before you lose your driving optimists."
              />
            </FeatureGrid>
            <div className="screenshotDashboardContainer">
              <div>
                <img
                  alt="screenshot of Elv"
                  className="screenshotDashboard1"
                  src="screenshot-dashboard-1.png"
                />
              </div>
              <img
                alt="screenshot of Elv"
                className="screenshotDashboard2"
                src="screenshot-dashboard-2.png"
              />
            </div>
          </section>
          <Divider />
          <section ref={matrixOrgsRef}>
            <TitleBig className="bigTitleMatrixOrg">
              Matrix organizations.
              <br />
              But <i>so</i> simple.
            </TitleBig>
            <TitleSub>
              The hardest problem for organization chart systems. Elegantly
              solved.
            </TitleSub>
            <FeatureGrid>
              <Feature
                title="People can have multiple roles."
                text="You connect people to roles, we simplify the picture with our magic."
              />
              <Feature
                title="Several orgcharts in one."
                text="Easily create an organization chart for all projects. Or any type of internal group."
              />
              <Feature
                title="Who needs email lists?"
                text="Drag-to-select and click 'email 57 people'."
              />
            </FeatureGrid>
            <img
              alt="screenshot of Elv"
              className="screenshotMatrix"
              src="screenshot-matrix.png"
            />
          </section>
          <Divider />
          <section ref={onboardingRef}>
            <TitleBig>
              Manage new hires
              <br />
              effectively
            </TitleBig>
            <TitleSub>
              Onboarding one person is easy, but onboarding 40 people is a
              problem, unless you have Elv.
            </TitleSub>
            <FeatureGrid>
              <Feature
                title="Checklists are used for preparing planes."
                text="Use checklists to get your new hires off the ground too. Simple and effective."
              />
              <Feature
                title="Self-served onboarding."
                text="Give both the manager and the new hire a complete checklist to get started."
              />
              <Feature
                title="Prevent security problems."
                text="New hires without proper security routines pose a severe threat to your business."
              />
              <Feature
                title="Make private notes about anyone."
                text="Remember the names of their spouse, their kids and their cat."
              />
              <Feature
                title="Stickies."
                text="Put up sticky notes where you need to follow up."
              />
              <Feature
                title="Map the competences of your employees."
                text="Create empty roles when you lack capabilites."
              />
            </FeatureGrid>
            <div style={{ textAlign: "center" }}>
              <img
                alt="screenshot of onboarding in Elv"
                className="screenshotOnboarding1"
                src="screenshot-onboarding.png"
              />
              <img
                alt="screenshot of onboarding in Elv"
                className="screenshotOnboarding2"
                src="screenshot-onboarding-many.png"
              />
            </div>
          </section>
          <Divider />
          <section>
            <TitleBig>
              Finish routine tasks in
              <br />
              seconds, not hours
            </TitleBig>
            <TitleSub>
              Any of these tasks can be done in *less* than 60 seconds. Your
              time is expensive, let's save{" "}
              <span style={{ whiteSpace: "nowrap" }}>more of it.</span>
            </TitleSub>
            <FeatureGrid>
              <Feature
                title="Contact details."
                text="Find contact details for anyone, or everyone."
              />
              <Feature
                title="Find profile pictures."
                text="Make a grid of headshots in seconds."
              />
              <Feature
                title="Are people treated fairly?"
                text="Compare gender, salary and seniority in just two clicks."
              />
              <Feature
                title="Email 100 people."
                text="No email list needed. Yes, really."
              />
              <Feature
                title="Get new hires up to speed."
                text="Check and update onboarding status of new hires. Individually, in groups, or altogether."
              />
              <Feature
                title="Headcounts."
                text="Get exact headcounts for companies, offices, genders, anything."
              />
              <Feature
                title="Show, don't tell."
                text="Need a screenshot of your organization chart for that presentation? One click, done."
              />
            </FeatureGrid>
          </section>
          <Divider />
          <section>
            <TitleBig>
              Love Excel?
              <br />
              So do we.
            </TitleBig>
            <TitleSub>
              Sometimes you need to put rows of employees into Excel - we got
              you covered.
            </TitleSub>
            <FeatureGrid className="featureGridContainerTwoColumns">
              <Feature
                title="Fill spreadsheet."
                text="Select employees, filter to your needs, export to Excel."
              />
              <Feature
                title="Lightning fast export."
                text="Works with Microsoft Excel, Google Sheets, Apple Numbers, and more."
              />
            </FeatureGrid>
          </section>
          <Divider />
          <section ref={integrationRef}>
            <TitleBig>Masterdata integration</TitleBig>
            <TitleSub>Integration is of utmost importance.</TitleSub>
            <FeatureGrid>
              <Feature title="SSO." text="Log in with your work account." />
              <Feature
                title="Active Directory."
                text="Elv connects to your Active Directory (AD) and stays up to date."
              />
              <Feature
                title="API services."
                text="For when your homegrown systems need fast access to employee data. Build with Elv to keep your developers happy."
              />
              <Feature title="GDPR compliance" text="Naturally." />
              <Feature
                title="Security excellence."
                text="We prioritised operational and strategic security from the start. Your data is in capable hands."
              />
            </FeatureGrid>
          </section>
          <Divider />
          <section>
            <Video />
          </section>
          <Divider />
          <section className="finalSection">
            <TitleSub>Book a 30 minute demo to see Elv in action.</TitleSub>
            <DemoButton />
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
