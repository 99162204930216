import "./Header.css";

const scrollOptions: ScrollIntoViewOptions = {
  behavior: "smooth",
  block: "center",
};

type Props = {
  employeeDirectory: React.RefObject<HTMLElement>;
  matrixOrgs: React.RefObject<HTMLElement>;
  onboarding: React.RefObject<HTMLElement>;
  integration: React.RefObject<HTMLElement>;
};

const Header = (props: Props) => {
  return (
    <header className="header">
      <div className="headerLeft">
        <img alt="logo" className="logo" src="logo-square.png" />
        <img alt="logotext" className="logotext" src="logo-text.png" />
        <span className="tagline">For Modern HR Teams</span>
        <menu className="menu">
          {/* <button
            onClick={() => {
              props.employeeDirectory.current?.scrollIntoView(scrollOptions);
            }}
          >
            Employee directory
          </button>
          <button
            onClick={() => {
              props.matrixOrgs.current?.scrollIntoView(scrollOptions);
            }}
          >
            Matrix orgs
          </button>
          <button
            onClick={() => {
              props.onboarding.current?.scrollIntoView(scrollOptions);
            }}
          >
            Onboarding
          </button>
          <button
            onClick={() => {
              props.integration.current?.scrollIntoView(scrollOptions);
            }}
          >
            Integration
          </button> */}
        </menu>
      </div>
      <div className="headerRight">
        <menu className="menu">
          <a className="mailto" href="mailto:hello@elv.tech">hello@elv.tech</a>
          <a
            className="demoButton"
            href="https://calendly.com/elv-michael/30min"
            target="_blank"
            rel="noopener noreferrer"
          >
            Book a demo
          </a>
        </menu>
      </div>
    </header>
  );
};

export default Header;
