import React from "react";
import "./TitleBig.css";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const TitleBig = ({ children, className }: Props) => {
  return (
    <div className={`titleBigContainer ${className ?? ""}`}>
      <h1 className="titleBigText">{children}</h1>
    </div>
  );
};

export default TitleBig;
