import "./ScreenshotBig.css";

const ScreenshotBig = () => {
  return (
    <div className="screenshotContainer">
      <img
        alt="screenshot of Elv"
        className="screenshot-1"
        src="screenshot-1.png"
      />
    </div>
  );
};

export default ScreenshotBig;
