import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footerContent">
        <div>
          © Elv Technology AS
          <br />
          <br />
          NO 915 104 193
        </div>
        <div style={{ textAlign: "right" }}>
          hello@elv.tech
          <br />
          <br />
          <b>or call us at:</b>
          <br />
          <br />
          +47 473 25 149
        </div>
      </div>
      <div className="disclaimer">Elv Technology is a part of Elv AS.</div>
    </footer>
  );
};

export default Footer;
