import React from "react";
import "./FeatureGrid.css";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const FeatureGrid = ({ children, className }: Props) => {
  return <div className={`featureGridContainer ${className}`}>{children}</div>;
};

export default FeatureGrid;
