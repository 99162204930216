import React from "react";
import "./FeatureGrid.css";

type Props =
  | {
      title: string;
      text: string;
    }
  | {
      children: React.ReactNode;
    };

const FeatureGrid = (props: Props) =>
  "children" in props ? (
    <div className="featureGridItem">{props.children}</div>
  ) : (
    <div className="featureGridItem">
      <span className="featureBold">{props.title}</span>{" "}
      <span className="featureText">{props.text}</span>
    </div>
  );

export default FeatureGrid;
