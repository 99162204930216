import "./DemoButton.css";

const DemoButton = () => (
  <div className="demoButtonContainer">
    <a
      className="demoButton"
      href="https://calendly.com/elv-michael/30min"
      target="_blank"
      rel="noreferrer noopener"
    >
      Book a demo
    </a>
  </div>
);

export default DemoButton;
